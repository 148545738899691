.footer {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    background-color: rgb(90, 90, 90);
    justify-self: bottom;
}

.footerText {
    padding: 1px;
    color: white;
    margin:0;
    margin-left: 1%
}