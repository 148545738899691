.pageHeader {
  color: black;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.pageBodyContainer {
  padding-left: 5%;
  padding-right: 5%;
  max-width: 80%;
  min-width: 80%;
  background-color: white;
  min-height: 1000px;
}

.pageBodyBed {
  display: flex;
  justify-content: center;
  background-color: rgb(194, 194, 194);
  border: 2px soli;
}

.attendenceForm {
  max-width: 80%;
  margin: 0 auto;
  padding: 5%;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formGroup {
  margin-bottom: 2.5%;
}

.formLabel {
  display: block;
  margin-bottom: 1%;
  font-weight: bold;
}

.formInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.formSubmit {
  width: 100%;
  padding: 2.5%;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.formSubmit:hover {
  background-color: #45a049;
}

.notification {
  max-width: 80%;
  margin: 5% auto;
  padding: 1.5%;
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.errorNotification {
  max-width: 80%;
  margin: 5% auto;
  padding: 1.5%;
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.emptyBox {
  max-width: 500px;
  margin: 20px auto;
  padding: 21px;
}

/* pages.css */

.hero-image {
  position: relative;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 2px 2px 4px #000;
}

.hero-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1;
}

.hero-image h1 {
  position: relative;
  z-index: 2;
}

/* .pageBodyBed {
  padding: 2.5%;
} */

/* .pageBodyContainer {
  background: #f8f9fa;
  padding: 2.5%;
  border-radius: 8px;
} */

.pageHeader {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.pageBody {
  padding-left: 5%;
  padding-right: 5%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.image-container {
  display: flex;
  justify-content: center;
}

.selfieImage {
  min-width: 60%;
  max-width: 100%;
  height: auto;
}

.blur {
  filter: blur(5px);
  pointer-events: none; /* Prevent interactions */
}

/* pages.css */
/* .pageBodyContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.formGroup {
  margin-bottom: 15px;
}

.formLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.formSubmit {
  background-color: #3498db; /* Customize color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.formSubmit:disabled {
  background-color: #ccc;
}

.notification {
  color: green;
  margin: 10px 0;
  text-align: center; /* Center the text */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
}

.errorNotification {
  color: red;
  margin: 10px 0;
  text-align: center; /* Center the text */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
}

.blur {
  filter: blur(2px);
}

.formContainer {
  display: flex;
  flex-direction: column; /* Align items in a column */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  height: 40vh; /* Optional: fill the full height of the viewport */
  padding: 20px; /* Optional: add some padding */
}
